import React from "react"
import Video from "./Video"
import Button from "./button"

export interface PageHeaderProps {
  smallTitle: string
  mainTitle: string
  description: string
  video: {
    url: string
    cover: string
  }
  ctaLink?: string
  ctaText?: string
  onCtaClick?: () => void
}

export default function PageHeader(props: PageHeaderProps) {
  // Original Tailwind UI component: https://tailwindui.com/components/marketing/sections/heroes#component-351873ecf5c4a3f4708d4513ea7738f6
  return (
    <div className="flex justify-center overflow-hidden">
      <div className="pt-6 pb-6 sm:pb-6">
        <main className="mt-6 max-w-7xl px-4 sm:px-6">
          <div className="lg:grid lg:grid-cols-12 lg:gap-8">
            <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
              <h1 className="">
                <span className="block text-sm font-semibold tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">
                  {props.smallTitle}
                </span>
                <span className="mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl">
                  <span className="text-gray-900">{props.mainTitle}</span>
                </span>
              </h1>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl font-sans">
                {props.description}
              </p>
              {props.ctaText && props.ctaLink && (
                <div className="pt-4">
                  <Button
                    text={props.ctaText}
                    href={props.ctaLink}
                    onClick={props.onCtaClick}
                  />
                </div>
              )}
            </div>
            <div className="mt-4 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
              <svg
                className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-8 scale-75 origin-top sm:scale-100 lg:hidden"
                width={640}
                height={784}
                fill="none"
                viewBox="0 0 640 784"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="4f4f415c-a0e9-44c2-9601-6ded5a34a13e"
                    x={118}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-secondary-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  y={72}
                  width={640}
                  height={640}
                  className="text-secondary opacity-5"
                  fill=""
                />
                <rect x={118} width={404} height={784} fill="" />
              </svg>
              <Video url={props.video.url} image={props.video.cover} />
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
