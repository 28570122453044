import React from "react"
import { Videos, Profiles } from "../../data/assets"
import { Routes } from "../../data/routes"
import { FamilyPlanFAQs } from "../../data/faq"
import { Link } from "gatsby"
import Img from "../../components/img"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

import Card from "../../components/card"
import CTA from "../../components/CTA"
import { SecondaryButton } from "../../components/button"
import PageHeader from "../../components/PageHeader"
import Testimonial from "../../components/Testimonial"
import ValuesList from "../../components/ValuesList"
import HowItWorks from "../../components/HowItWorks"

import {
  LoginIcon,
  CurrencyDollarIcon,
  AcademicCapIcon,
  KeyIcon,
} from "@heroicons/react/outline"
import { Disclosure } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/outline"

const Content = {
  pageName: "Families",
  subheader: "Individual plans",
  header: `Yup's Math Learning Support System means 24/7, unlimited,
    1:1 math tutoring when and where you need it`,
  description: `Because everyone needs a hand sometimes`,
  video: Videos.whatIsYup,

  // Testimonial section
  testimonial: `Yup tutors take the time to make sure my daughter
    understands concepts behind problems, in a way that is supportive
    and encouraging. She went from stressed out to confident and
    received an A on her exam.`,
  testimonialProfile: Profiles.elizabeth,
  testimonialName: "Elizabeth W.",
  testimonialJob: "Parent to 10th Grader Nicole, Los Angeles",

  // Login section
  loginHeader: "Login or sign up",
  loginOptions: [
    {
      name: "Existing user",
      text: "Already have a Yup login?",
      link: "https://student.yup.com/",
      icon: LoginIcon,
      action: "Student login",
    },
    {
      name: "New to Yup?",
      text: "Check out our personal learning plans",
      link: Routes.plans,
      icon: CurrencyDollarIcon,
      action: "See plans",
    },
  ],

  // Features section
  featuresHeader: "Because who doesn't need a helping hand sometimes?",
  features: [
    {
      title: "24/7 and virtual",
      text: "Access us anytime, anywhere",
      image: "family-feature-1.jpeg",
    },
    {
      title: "1:1 and tailored to you",
      text: "Our tutors meet you where you're at to ensure great learning",
      image: "family-feature-2.jpeg",
    },
    {
      title: "Boost your grades and your confidence",
      text: "Be sure you can succeed in and out of the classroom",
      image: "family-feature-3.jpeg",
    },
  ],

  // How it works section
  howItWorksHeader:
    "Yup offers 1:1, unlimited expert math tutoring - anytime, anywhere",
  howItWorksText: `We get it - you're busy. Who knows when you have time to do
    homework, and teachers can't always be around to answer your
    questions. Yup's online and mobile platforms enable acccess to
    expert math tutoring any time of day, from anywhere.`,
  howItWorksAction: "Learn More",
  howItWorksLink: Routes.features,

  // Values section
  values: [
    {
      header: "Expert tutors who you can trust",
      icon: AcademicCapIcon,
      description: `We know how important it is to for students to feel comfortable
        with their teachers.

        Our rigorous application process ensures tutors have strong math
        fundamentals and great communication skills, so they can work with students of all ages.

        Tutors receive ongoing training and support from our education
        experts to ensure continued success.`,
      action: "Meet our tutors",
      link: Routes.ourTutors,
      image: "icon-quality.svg",
    },
    {
      header: "Privacy and security, guaranteed",
      icon: KeyIcon,
      description: `Working with students means privacy and security is always top of mind.
        Yup guarantees compliance - and beyond - with a number of Federal and State policies to
        ensure safe and fun learning interactions.`,
      action: "How Yup protects students",
      link: Routes.privacy,
      image: "icon-boy-phone.svg",
    },
  ],

  // CTA section
  ctaTitle: "Ready to sign up?",
  ctaActions: [
    {
      link: Routes.plans,
      text: "Purchase a Yup plan",
    },
  ],

  // FAQ section
  faqHeader: "Frequently Asked Questions",
  faqs: FamilyPlanFAQs,
}

function LoginSection() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/header#component-547517d4dc48e1bf0ccab051ebb0dcba
  return (
    <div>
      {/* Header */}
      <div className="relative pb-24">
        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
          <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 md:text-5xl lg:text-6xl">
            {Content.loginHeader}
          </h2>
        </div>
      </div>

      {/* Overlapping cards */}
      <section
        className="-mt-32 max-w-7xl mx-auto relative z-10 pb-16 px-4 sm:px-6 lg:px-8"
        aria-labelledby="contact-heading"
      >
        <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-2 lg:gap-x-8 lg:gap-y-16">
          {Content.loginOptions.map(option => (
            <div
              key={option.name}
              className="flex flex-col bg-white rounded-2xl shadow-lg"
            >
              <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
                <div className="absolute top-0 p-5 inline-block bg-secondary rounded-xl shadow-lg transform -translate-y-1/2">
                  <option.icon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </div>
                <h3 className="text-xl font-medium text-gray-900">
                  {option.name}
                </h3>
                <p className="mt-4 text-base text-gray-500">{option.text}</p>
              </div>
              <div className="p-6 bg-secondary-50 rounded-bl-2xl rounded-br-2xl md:px-8">
                <a
                  href={option.link}
                  target="_blank"
                  className="text-base font-medium text-secondary hover:text-secondary-700"
                >
                  {option.action}
                  <span aria-hidden="true"> &rarr;</span>
                </a>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  )
}

function FeaturesSection() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/blog-sections#component-720cf60b960fecb99c45f462f24db2d9
  return (
    <div className="relative py-16 px-4 sm:px-6 lg:px-8">
      <div className="absolute inset-0">
        <div className="h-1/3 sm:h-2/3" />
      </div>
      <div className="relative max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
            {Content.featuresHeader}
          </h2>
        </div>
        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {Content.features.map(feature => (
            <div
              key={feature.title}
              className="flex flex-col rounded-lg shadow-lg overflow-hidden"
            >
              <div className="flex-shrink-0">
                <Img
                  src={feature.image}
                  alt={feature.title}
                  className="h-48 w-full"
                  cover={true}
                />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-xl font-semibold text-gray-900">
                    {feature.title}
                  </p>
                  <p className="mt-3 text-base text-gray-500">{feature.text}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

function HowItWorksSection() {
  // Original Tailwind UI component: https://tailwindui.com/components/marketing/sections/cta-sections#component-25ce2a81d17545994655ccc4062651a4
  return (
    <Card>
      <div className="flex flex-col lg:flex-row text-center lg:text-left items-center">
        <div className="px-4">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            {Content.howItWorksHeader}
          </h2>
          <p className="mt-5 text-xl text-gray-500">{Content.howItWorksText}</p>
        </div>
        <div className="text-center w-full py-8">
          <SecondaryButton
            text={Content.howItWorksAction}
            href={Content.howItWorksLink}
          />
        </div>
      </div>
      <HowItWorks />
    </Card>
  )
}

function FAQSection() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/faq-sections#component-8699d80b13ef524eb573e54b4d4b89d1
  function FAQLink(props: { action: string; link: string }) {
    if (props.link.startsWith("http") || props.link.startsWith("mailto")) {
      return (
        <a className="text-blue" href={props.link} target="_blank">
          {props.action}.
        </a>
      )
    }
    return (
      <Link to={props.link} className="text-blue">
        {props.action}.
      </Link>
    )
  }

  return (
    <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
        <h1 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
          {Content.faqHeader}
        </h1>
        <dl className="mt-6 space-y-6 divide-y divide-gray-200">
          {Content.faqs.map(faq => (
            <Disclosure as="div" key={faq.question} className="pt-6">
              {({ open }) => (
                <>
                  <dt className="text-lg">
                    <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                      <span className="font-medium text-gray-900">
                        {faq.question}
                      </span>
                      <span className="ml-6 h-7 flex items-center">
                        <ChevronDownIcon
                          className={`h-6 w-6 transform ${
                            open ? "-rotate-180" : "rotate-0"
                          }`}
                          aria-hidden="true"
                        />
                      </span>
                    </Disclosure.Button>
                  </dt>
                  <Disclosure.Panel as="dd" className="mt-2 pr-12">
                    <p className="text-base text-gray-500">
                      {faq.answer}&nbsp;
                      {faq.action && (
                        <FAQLink action={faq.action} link={faq.link} />
                      )}
                    </p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </dl>
      </div>
    </div>
  )
}

const Families = () => {
  return (
    <Layout>
      <Seo
        title={Content.pageName}
        description={Content.header}
        route={Routes.families}
      />
      <PageHeader
        smallTitle={Content.subheader}
        mainTitle={Content.header}
        description={Content.description}
        video={Content.video}
      />
      <Testimonial
        image={Content.testimonialProfile}
        testimonial={Content.testimonial}
        name={Content.testimonialName}
        jobs={[Content.testimonialJob]}
      />
      <LoginSection />
      <FeaturesSection />
      <HowItWorksSection />
      <ValuesList values={Content.values} />
      <CTA title={Content.ctaTitle} actions={Content.ctaActions} />
      <FAQSection />
    </Layout>
  )
}

export default Families
